import { useEffect, memo } from "react";
import { LoadingSkeleton } from "cerulean";
import { modules } from "byzantine";
import Container from "../../Container";
import AccountSelectScreen from "./AccountSelectScreen";
import { LimitsForDisplayType } from "../ach/ACHPaymentRoutes";

function TransfersContainer({ limits }: { limits: LimitsForDisplayType }) {
  const { loading, load } = modules.transfers.bootstrap.useBootstrap();
  useEffect(() => {
    load();
  }, [load]);

  return (
    <LoadingSkeleton isLoading={loading}>
      <Container>
        <AccountSelectScreen limits={limits} />
      </Container>
    </LoadingSkeleton>
  );
}

export default memo(TransfersContainer);
