import { useState, ReactNode, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, Tooltip } from "cerulean";
import { useLocalization } from "@fluent/react";
import { Table } from "semantic-ui-react";
import Filters from "byzantine/src/filters";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import Loading from "../Loading";
import { SelectedDualApprovalsMapperType } from "./DualApprovalContainer";
import styles from "./DualApproval.module.scss";
import DualApprovalDrawer from "./DualApprovalDrawer";

type DualApprovalRequestCheckboxType = {
  isSelected: boolean;
  handleToggleCheckbox: () => void;
};

type TableCellWrapperType = {
  children: ReactNode;
  dualApprovalId: string;
} & React.ComponentProps<typeof Table.Cell>;

type DualApprovalPendingTableType = {
  dualApprovalRequests: DualApprovalRequest[];
  selectedDualApprovals: SelectedDualApprovalsMapperType;
  handleToggleCheckbox: (dualApproval: DualApprovalRequest) => void;
  handleToggleCheckboxHeader: (isSelected: boolean) => void;
  setDualApprovals: React.Dispatch<React.SetStateAction<DualApprovalRequest[]>>;
  handleSingleApprove: (dualApproval: DualApprovalRequest) => void;
  handleSingleReject: (dualApproval: DualApprovalRequest) => void;
  loading: boolean;
};

type DualApprovalTableSortConfigType = {
  column: "date" | "to" | "submittedBy" | "amount" | null;
  direction: "asc" | "desc" | null;
};

const DualApprovalCheckbox = ({
  isSelected,
  handleToggleCheckbox,
}: DualApprovalRequestCheckboxType) => {
  const handleToggle = () => {
    handleToggleCheckbox();
  };

  return <Checkbox onChange={handleToggle} checked={isSelected} />;
};

const DualApprovalPendingTable = ({
  dualApprovalRequests,
  setDualApprovals,
  selectedDualApprovals,
  handleToggleCheckboxHeader,
  handleToggleCheckbox,
  handleSingleApprove,
  handleSingleReject,
  loading,
}: DualApprovalPendingTableType) => {
  const showControls = dualApprovalRequests.length > 1;
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const { dualApprovalId: selectedDualApprovalId } = useParams();
  const [sortConfig, setSortConfig] = useState<DualApprovalTableSortConfigType>(
    { column: "date", direction: "desc" },
  );

  const [clickedDualApprovalIndex, setClickedDualApprovalIndex] =
    useState<number>(-1);

  useEffect(() => {
    const selectedIndex = dualApprovalRequests.findIndex(
      (approvalRequest) => approvalRequest.uuid === selectedDualApprovalId,
    );
    if (selectedIndex > -1) {
      setClickedDualApprovalIndex(selectedIndex);
    }
  }, [selectedDualApprovalId, dualApprovalRequests]);

  const sortApprovals = (column: DualApprovalTableSortConfigType["column"]) => {
    const isSameColumn = sortConfig.column === column;

    // on first sort (aka on first click), sort should be asc
    const direction =
      isSameColumn && sortConfig.direction === "asc" ? "desc" : "asc";

    const getSortedData = () => {
      let sortedData: DualApprovalRequest[] = [];
      if (column === "date") {
        sortedData = dualApprovalRequests.sort((a, b) =>
          direction === "asc"
            ? a.created_at.getTime() - b.created_at.getTime()
            : b.created_at.getTime() - a.created_at.getTime(),
        );
      } else if (column === "to") {
        sortedData = dualApprovalRequests.sort((a, b) =>
          direction === "asc"
            ? a.action.recipientName.localeCompare(b.action.recipientName)
            : b.action.recipientName.localeCompare(a.action.recipientName),
        );
      } else if (column === "submittedBy") {
        sortedData = dualApprovalRequests.sort((a, b) =>
          direction === "asc"
            ? a.requester.first_name.localeCompare(b.requester.first_name)
            : b.requester.first_name.localeCompare(a.requester.first_name),
        );
      } else if (column === "amount") {
        sortedData = dualApprovalRequests.sort((a, b) =>
          direction === "asc"
            ? a.action.amount - b.action.amount
            : b.action.amount - a.action.amount,
        );
      }
      return sortedData;
    };

    const data = getSortedData();
    setSortConfig({ column, direction });
    setDualApprovals(data);
  };

  const isCheckboxHeaderSelected = dualApprovalRequests.every(
    (approvalRequest) => selectedDualApprovals?.[approvalRequest.uuid],
  );

  const ArrowIcon = ({
    column,
  }: {
    column: DualApprovalTableSortConfigType["column"];
  }) => {
    const { direction, column: columnConfig } = sortConfig;
    const isSameColumn = columnConfig === column;

    const getIconClass = () => {
      if (!isSameColumn || direction === "asc") {
        return "narmi-icon-arrow-up";
      }
      if (direction === "desc") {
        return "narmi-icon-arrow-down";
      }
      return "narmi-icon-arrow-up";
    };

    return (
      <span className={`fontSize--xs margin--left--xxs ${getIconClass()}`} />
    );
  };

  const handleOpenDrawer = (dualApprovalId: string) => {
    navigate(`/${dualApprovalId}`);
  };

  const handleCloseDrawer = () => {
    navigate("/");
    setClickedDualApprovalIndex(-1);
  };

  const handleOnNext = () => {
    const nextIndex = clickedDualApprovalIndex + 1;

    // if it's the last one and there's no next, close the drawer
    if (nextIndex === dualApprovalRequests.length) {
      handleCloseDrawer();
    } else {
      navigate(`/${dualApprovalRequests[nextIndex].uuid}`);
    }
  };

  const handleOnPrev = () => {
    const prevIndex = clickedDualApprovalIndex - 1;

    // if it's the first one and there's no prev, close the drawer
    if (prevIndex < 0) {
      handleCloseDrawer();
    } else {
      navigate(`/${dualApprovalRequests[prevIndex].uuid}`);
    }
  };

  const handleApproveFromDrawer = () => {
    const dualApprovalRequest = dualApprovalRequests[clickedDualApprovalIndex];
    handleSingleApprove(dualApprovalRequest);
  };

  const handleRejectFromDrawer = () => {
    const dualApprovalRequest = dualApprovalRequests[clickedDualApprovalIndex];
    handleSingleReject(dualApprovalRequest);
  };

  const isDrawerOpen = clickedDualApprovalIndex > -1;

  if (loading && !isDrawerOpen) {
    return <Loading />;
  }

  if (dualApprovalRequests.length === 0) {
    return (
      <div className="emptyState">
        {l10n.getString("heading-no-pending-approvals")}
      </div>
    );
  }

  const TableCellWrapper = ({
    children,
    dualApprovalId,
    ...props
  }: TableCellWrapperType) => (
    <Table.Cell {...props} onClick={() => handleOpenDrawer(dualApprovalId)}>
      {children}
    </Table.Cell>
  );

  return (
    <>
      <Table selectable className={styles.table}>
        <Table.Header className={styles.tableHeader}>
          <Table.Row>
            <Table.HeaderCell collapsing>
              <DualApprovalCheckbox
                isSelected={isCheckboxHeaderSelected}
                handleToggleCheckbox={() =>
                  handleToggleCheckboxHeader(isCheckboxHeaderSelected)
                }
              />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortApprovals("date")} width={3}>
              {l10n.getString("th-scheduled-date")}
              <ArrowIcon column="date" />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortApprovals("to")} width={3}>
              {l10n.getString("th-to")}
              <ArrowIcon column="to" />
            </Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortApprovals("amount")}>
              {l10n.getString("th-amount")}
              <ArrowIcon column="amount" />
            </Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => sortApprovals("submittedBy")}
              width={3}
            >
              {l10n.getString("th-submitted-by")}
              <ArrowIcon column="submittedBy" />
            </Table.HeaderCell>
            <Table.HeaderCell>{l10n.getString("th-type")}</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dualApprovalRequests.map(
            (dualApprovalRequest: DualApprovalRequest) => {
              const {
                uuid,
                created_at: paymentCreatedAt,
                action,
                requester,
              } = dualApprovalRequest;
              const { dollarAmount, type, recipientName } = action;
              // TODO: add this logic in DualApprovalRequest in byzantine
              const showDateTooltip =
                new Date().setHours(0, 0, 0, 0) >
                new Date(paymentCreatedAt).setHours(0, 0, 0, 0);
              let showRecentlyChangedTag;

              if (action.type === "ACH") {
                const recipientUpdatedDate = new Date(
                  action.recipientLastUpdated,
                );
                const threeDaysAgoFromPaymentSubmitted = new Date(
                  paymentCreatedAt,
                );
                threeDaysAgoFromPaymentSubmitted.setDate(
                  threeDaysAgoFromPaymentSubmitted.getDate() - 3,
                );
                showRecentlyChangedTag =
                  recipientUpdatedDate > threeDaysAgoFromPaymentSubmitted;
              }

              return (
                <Table.Row
                  key={`table-row__${uuid}`}
                  className={styles.hoverableRow}
                >
                  <Table.Cell>
                    <DualApprovalCheckbox
                      isSelected={Boolean(selectedDualApprovals[uuid])}
                      handleToggleCheckbox={() => {
                        handleToggleCheckbox(dualApprovalRequest);
                      }}
                    />
                  </Table.Cell>
                  <TableCellWrapper dualApprovalId={uuid}>
                    {Filters.americanDate(paymentCreatedAt.toISOString())}
                    {showDateTooltip ? (
                      <Tooltip
                        maxWidth="210px"
                        text={l10n.getString("pending-drawer-overdue-copy")}
                      >
                        <span className="fontSize--m narmi-icon-alert-circle fontColor--error margin--left--xs" />
                      </Tooltip>
                    ) : null}
                  </TableCellWrapper>

                  <TableCellWrapper dualApprovalId={uuid}>
                    {recipientName}
                    {showRecentlyChangedTag ? (
                      <Tooltip
                        maxWidth="210px"
                        text={l10n.getString(
                          "pending-drawer-recently-changed-copy",
                        )}
                      >
                        <span className="fontSize--m narmi-icon-alert-circle fontColor--error margin--left--xs" />
                      </Tooltip>
                    ) : null}
                  </TableCellWrapper>

                  <TableCellWrapper dualApprovalId={uuid}>
                    {Filters.currency(dollarAmount, { hasDecimal: true })}
                  </TableCellWrapper>

                  <TableCellWrapper dualApprovalId={uuid}>
                    {requester.getDescription()}
                  </TableCellWrapper>

                  <TableCellWrapper dualApprovalId={uuid}>
                    {type}
                  </TableCellWrapper>

                  <TableCellWrapper
                    dualApprovalId={uuid}
                    className={styles.viewCtaContainer}
                  >
                    <span className={styles.viewCtaText}>View</span>
                    <span className="fontSize--m narmi-icon-chevron-right" />
                  </TableCellWrapper>
                </Table.Row>
              );
            },
          )}
        </Table.Body>
      </Table>
      <DualApprovalDrawer
        handleApprove={handleApproveFromDrawer}
        handleReject={handleRejectFromDrawer}
        handleOnNext={handleOnNext}
        handleOnPrev={handleOnPrev}
        isOpen={isDrawerOpen}
        showControls={showControls}
        handleClose={handleCloseDrawer}
        drawerType="pending"
        dualApproval={dualApprovalRequests[clickedDualApprovalIndex]}
      />
    </>
  );
};

export default DualApprovalPendingTable;
