/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import type { TransfersSliceState } from "./types";

const name = "transfers";

export const initialState: TransfersSliceState = {
  destinationInstantEligible: false,
};

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    eligible: (
      state: TransfersSliceState,
      action: PayloadAction<{ online: boolean; enrollment_level: string }>,
    ) => {
      state.destinationInstantEligible =
        action.payload.online &&
        ["receive", "send_receive", "rfp", "rfp_send"].includes(
          action.payload.enrollment_level,
        );
    },
    resetEligible: (state: TransfersSliceState) => {
      state.destinationInstantEligible = false;
    },
  },
});

export const { actions } = slice;

export default {
  [name]: slice.reducer,
};
