import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Account from "byzantine/src/Account";
import Dashboard from "../dashboard/Dashboard";
import SavvyMoneyDashboard from "../savvy_money/SavvyMoneyDashboard";
import { AccountContextProvider } from "../contexts/AccountContext";
import MainLayout from "./MainLayout";
import { LegacyNafUrl } from "../../types";
import ScrollToTopOnRouteChange from "../ScrollToTopOnRouteChange";
import PositivePay from "../positivePay/component";

interface MainProps {
  accounts: Account[];
  features: API.Features;
  institution: API.Institution;
  legacyNafUrls: LegacyNafUrl[];
  deviceIsRemembered: boolean;
  replyTime: string;
}

const Main = ({
  accounts,
  features,
  institution,
  legacyNafUrls,
  deviceIsRemembered,
  replyTime,
}: MainProps) => (
  <AccountContextProvider accounts={accounts}>
    <Router basename="/">
      <ScrollToTopOnRouteChange />
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout
              legacyNafUrls={legacyNafUrls}
              deviceIsRemembered={deviceIsRemembered}
              institution={institution}
            />
          }
        >
          {["/", "/dashboard"].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <Dashboard
                  features={features}
                  institution={institution}
                  replyTime={replyTime}
                />
              }
            />
          ))}
          {features.olb_enable_credit_monitor && (
            <Route
              path="/credit_monitor"
              element={<SavvyMoneyDashboard features={features} />}
            />
          )}
          <Route path="/positive_pay" element={<PositivePay />} />
          {/* Catch-all route to redirect to dashboard */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Router>
  </AccountContextProvider>
);

export default Main;
