import { useCallback, useEffect, useMemo } from "react";

import { DateTime } from "luxon";
import { useSelector } from "react-redux";

import { useObjectMemo } from "../../../hooks";
import { useFeature } from "../features";
import {
  useBAccount,
  recipients,
  useInstitution,
  achCompanies,
} from "../../entities";
import { useLibrary } from "../../../providers";
import filters from "../../../../filters";
import { getTotalCountFromRecurrenceString } from "../../../../Recurrence";

import {
  ACHPaymentForm,
  paymentDetailsValidationSchema,
  additionalDetailsValidationSchema,
} from "./ACHPayment.form";
import { useManageRecipientPermission } from "./permissions";
import { formatValuesForRecurrenceString } from "./utils";

export const useSECCodes = () => {
  const { features } = useFeature();
  return features.allowed_std_ent_cls_codes || [];
};

export const useAdditionalDetails = () => {
  const institution = useInstitution();
  const { features } = useFeature();
  const sudoModeRequired = institution?.sudo_mode_required_for_ach_payments;
  const achScheduledEnabled = features.ach_payments_scheduled;
  const form = ACHPaymentForm.useForm();
  const { submitForm, setValidationSchema } = form;
  const recipient = recipients.useSelectedRecipient();
  const { clearAllToasts } = useLibrary("toasts");

  useEffect(() => {
    // TODO: remove when cleaning up ach scheduled flag
    if (achScheduledEnabled) {
      setValidationSchema(additionalDetailsValidationSchema);
    }
  }, [achScheduledEnabled, setValidationSchema]);

  const onContinue = useCallback(
    async (next: () => void, trySudo: () => void) => {
      const { success } = await submitForm();
      if (!success) {
        return null;
      }

      clearAllToasts();

      if (!sudoModeRequired) {
        return next();
      }

      return trySudo();
    },
    [clearAllToasts, submitForm, sudoModeRequired],
  );

  return useObjectMemo({
    onContinue,
    recipient,
    form,
  });
};

export const usePaymentDetails = () => {
  const institution = useInstitution();
  const { features } = useFeature();
  const sudoModeRequired = institution?.sudo_mode_required_for_ach_payments;
  const achScheduledEnabled = features.ach_payments_scheduled;
  const form = ACHPaymentForm.useForm();
  const { submitForm, setValidationSchema, validationSchema, values } = form;
  const recipient = recipients.useSelectedRecipient();
  const { clearAllToasts } = useLibrary("toasts");

  useEffect(() => {
    // TODO: remove when cleaning up ach scheduled flag
    if (achScheduledEnabled) {
      setValidationSchema(paymentDetailsValidationSchema);
    }
  }, [achScheduledEnabled, setValidationSchema]);

  const onContinue = useCallback(
    async (next: () => void, trySudo: () => void) => {
      const { success } = await submitForm();

      if (!success) {
        return null;
      }

      clearAllToasts();

      // TODO: remove sudo logic when cleaning up ach scheduled flag
      if (achScheduledEnabled || !sudoModeRequired) {
        return next();
      }

      return trySudo();
    },
    [clearAllToasts, submitForm, sudoModeRequired, achScheduledEnabled],
  );

  return useObjectMemo({
    onContinue,
    recipient,
    form,
  });
};

export const useReviewDetails = () => {
  const {
    values: { fromAccount, amount, secCode, startDate, recurringRule },
  } = ACHPaymentForm.useForm();

  const achCompany = achCompanies.useACHCompany();
  const recipient = recipients.useSelectedRecipient();
  const account = useBAccount(fromAccount as API.AccountId);
  const t = useLibrary("translations");

  const { calculateTotalPayments } = useCalculateTotalPayments();
  const numOfPayments = calculateTotalPayments();

  return useMemo(() => {
    if (!recipient || !account || !amount || !secCode) {
      return null;
    }

    type Section = {
      header: string;
      rows: Array<{ header: string; value: string }>;
    };

    const unknown = t.getString("ach-payment-review-unknown", {}, "Unknown");

    const totalPayments = calculateTotalPayments();
    const totalAmount = (totalPayments * amount) as Cents;

    const paymentSchedule = {
      header: t.getString(
        "ach-payment-review-payment-schedule-header",
        {},
        "Payment schedule",
      ),
      rows: [
        {
          header: t.getString(
            "ach-payment-review-start-date",
            {},
            "Start date",
          ),
          value: filters.americanDate(startDate) || "",
        },
        {
          header: t.getString("ach-payment-review-frequency", {}, "Frequency"),
          value: recurringRule?.frequency || "",
        },
        {
          header: t.getString("ach-payment-review-end-date", {}, "End date"),
          value: recurringRule?.untilDate || "",
        },
        {
          header: `${numOfPayments} ${t.getString("ach-payment-review-payments-total", {}, "payments total")}`,
          value: filters.currency(totalAmount),
        },
      ],
    };

    const paymentDetails = {
      header: t.getString(
        "ach-payment-review-payment-details-header",
        {},
        "Payment details",
      ),
      rows: [
        {
          header: t.getString("ach-payment-review-from ", {}, "From"),
          value: account.getShortDescription(false),
        },
        {
          header: t.getString(
            "ach-payment-review-company",
            {},
            "Company name / ID",
          ),
          value: achCompany
            ? `${achCompany.company_name} / ${achCompany.company_id}`
            : unknown,
        },
        {
          header: t.getString("ach-payment-review-date", {}, "Send date"),
          value: DateTime.now().toFormat("MM/dd/yyyy"),
        },
        {
          header: t.getString(
            "ach-payment-review-transaction-type",
            {},
            "Transaction type",
          ),
          value: t.getString(
            "ach-payment-review-sec-code",
            { secCode },
            `${secCode} payment`,
          ),
        },
      ],
    };

    const recipientDetails = {
      header: t.getString(
        "ach-payment-review-recipient-details-header",
        {},
        "Recipient details",
      ),
      rows: [
        {
          header: t.getString("ach-payment-review-recipient", {}, "Recipient"),
          value: recipient.name,
        },
        {
          header: t.getString("ach-payment-review-bank", {}, "Bank"),
          value: recipient.ach_destination?.institution_name || unknown,
        },
        {
          header: t.getString(
            "ach-payment-review-account",
            {},
            "Account number",
          ),
          value: recipient.ach_destination?.account_number || unknown,
        },
        {
          header: t.getString(
            "ach-payment-review-routing",
            {},
            "Routing account",
          ),
          value: recipient.ach_destination?.routing_number || unknown,
        },
      ],
    };

    const sections: Array<Section> = [paymentDetails, recipientDetails];

    return {
      sections,
      paymentSchedule,
      buttonLabel: t.getString("ach-payment-review-action", {}, "Make payment"),
      amount: filters.currency(amount),
      recipient,
    };
  }, [
    account,
    achCompany,
    amount,
    calculateTotalPayments,
    numOfPayments,
    recipient,
    recurringRule?.frequency,
    recurringRule?.untilDate,
    secCode,
    startDate,
    t,
  ]);
};

export const useCalculateTotalPayments = () => {
  const form = ACHPaymentForm.useForm();
  const { values } = form;
  const { recurringRule } = values;

  // only calculate total payments when the repeated payment section is visible
  // and user has toggled either "on" or "after" (not "never"), or user changes start date
  const shouldCalculateTotalPayments =
    recurringRule?.ends === "on" || recurringRule?.ends === "after";

  const calculateTotalPayments = useCallback(() => {
    if (shouldCalculateTotalPayments) {
      // if user toggles "after" but there is no count, just set total payments to 0
      if (recurringRule?.ends === "after" && !recurringRule?.count) {
        return 0;
      }

      const { frequency, startDate, untilDate, count } =
        formatValuesForRecurrenceString(values);

      const calculatedTotalPayments = getTotalCountFromRecurrenceString(
        frequency,
        startDate,
        untilDate,
        count,
      );

      return calculatedTotalPayments;
    }

    return 0;
  }, [recurringRule, shouldCalculateTotalPayments, values]);

  return useObjectMemo({
    calculateTotalPayments,
  });
};

export const usePermissionedRecipients = () => {
  const hasManageRecipientPermission = useManageRecipientPermission();
  const permissionedRecipients = useSelector(recipients.selectSortedRecipients);

  return permissionedRecipients.filter(
    (r) => hasManageRecipientPermission || !!r.ach_destination,
  );
};
