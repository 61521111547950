import type { NetworkPayload } from "../../../composites";

export const API = {
  checkInstantEligible: ({
    routing_number,
  }: API.InstantEligibilityRequest): NetworkPayload => {
    return {
      url: `transfers/instant/eligibility/${routing_number}/`,
      options: {
        method: "GET",
      },
    };
  },
};
