import { useSelector } from "react-redux";

import { useAccount } from "../../entities";

import { TransferForm } from "./TransferFunds.form";

import type { RootState } from "../../entities";

export const useSelectedSourceAccount = () => {
  const {
    values: { fromAccount },
  } = TransferForm.useForm();
  return useAccount(fromAccount);
};

export const useSelectedDestinationAccount = () => {
  const {
    values: { toAccount },
  } = TransferForm.useForm();
  return useAccount(toAccount);
};

export const useIsInstantEligible = () => {
  return useSelector(
    (state: RootState) => state.transfers.destinationInstantEligible,
  );
};
