import { Route, Routes } from "react-router-dom";
import { ComposeProviders, modules } from "byzantine";
import Container from "../../Container";
import { SudoProvider, SudoRoutes } from "../../SudoContext";
import RequestOTP from "../../SudoContext/RequestOTP";
import SubmitOTP from "../../SudoContext/SubmitOTP";
import TransfersContainer from "./TransfersContainer";
import { LimitsForDisplayType } from "../ach/ACHPaymentRoutes";
import TransferDetails from "./TransferDetails";

export const TRANSFERS_ROUTE = "funds";
export const TRANSFER_DETAILS_ROUTE = "details";
export const TRANSFER_REVIEW_ROUTE = "review";
export const TRANSFER_RECEIPT_ROUTE = "receipt";

const providers = [
  modules.transfers.bootstrap.Provider,
  modules.transfers.TransferForm.Provider,
];

const TransferRoutes = ({ limits }: { limits: LimitsForDisplayType }) => (
  <ComposeProviders components={providers}>
    <Container>
      <SudoProvider basename={TRANSFERS_ROUTE}>
        <Routes>
          <Route path="/" element={<TransfersContainer limits={limits} />} />
          <Route path={TRANSFER_DETAILS_ROUTE} element={<TransferDetails />} />
          <Route path={TRANSFER_REVIEW_ROUTE} element={<>review</>} />
          <Route path={TRANSFER_RECEIPT_ROUTE} element={<>receipt</>} />
          <Route path={`${SudoRoutes.RequestOTP}`} element={<RequestOTP />} />
          <Route path={`${SudoRoutes.SubmitOTP}`} element={<SubmitOTP />} />
        </Routes>
      </SudoProvider>
    </Container>
  </ComposeProviders>
);

export default TransferRoutes;
