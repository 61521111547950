import { useEffect } from "react";
import { modules } from "byzantine";
import { useLocalization } from "@fluent/react";
import { useNavigate } from "react-router-dom";
import {
  TextInput,
  ResponsiveFlex,
  ProgressButtons,
  RadioButtons,
  DateInput,
} from "cerulean";
import { FREQUENCIES } from "byzantine/src/Recurrence";
import { DateTime } from "luxon";

import FrequencySelector from "../FrequencySelector";
import { useSudoContext } from "../../SudoContext";
import { TOTAL_STEPS } from "./AccountSelectScreen";
import HeaderWithSteps from "../../HeaderWithSteps";
import * as formAdapters from "../../../src/adapters";
import styles from "./TransferDetails.module.scss";
import { TRANSFER_REVIEW_ROUTE, TRANSFERS_ROUTE } from "./TransferRoutes";

const DeliverySpeedSelection = () => {
  const form = modules.transfers.TransferForm.useForm();

  function setInstant(value: boolean) {
    if (value) {
      form.setFieldValue("frequency", undefined, true);
      form.setFieldValue("date", undefined, true);
    }
    form.setFieldValue("instant", value, true);
  }

  return (
    <div className="padding--bottom--s">
      <h4 className="fontSize--m nds-sans padding--top--xs padding--bottom--s">
        Delivery speed
      </h4>
      <div className={styles.deliverySpeedSelectContainer}>
        <div className={styles.deliverySpeedSelectCard}>
          <RadioButtons
            alwaysShowDetails
            kind="input-card"
            name="instant"
            options={{
              "Arrives instantly": {
                details: "Fee",
                value: "true",
              },
            }}
            value={String(form.values.instant)}
            onChange={() => setInstant(true)}
          />
        </div>
        <div className={styles.deliverySpeedSelectCard}>
          <RadioButtons
            alwaysShowDetails
            kind="input-card"
            name="ach"
            options={{
              "Arrives in 1-3 business days": {
                details: "No fee",
                value: "false",
              },
            }}
            value={String(form.values.instant)}
            onChange={() => {
              setInstant(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const ScheduledTransferFields = () => {
  const form = modules.transfers.TransferForm.useForm();
  const isRecurring = form.values.frequency !== FREQUENCIES.ONCE;

  function setDate(value: string) {
    form.setFieldValue("date", value, true);
  }

  useEffect(() => {
    setDate(DateTime.now().toFormat("MM/dd/yyyy"));
    form.setFieldValue("frequency", FREQUENCIES.ONCE, true);
  }, []);

  return (
    <div>
      <div className="padding--bottom">
        <FrequencySelector
          label="Frequency"
          {...formAdapters.select(form, "frequency")}
        />
      </div>

      <DateInput
        minDate={
          !isRecurring
            ? "today"
            : DateTime.now().plus({ days: 1 }).toFormat("MM/dd/yyyy")
        }
        dateFormat={"m/d/Y"}
        altInput={true}
        altFormat={"m/d/Y"}
        useIsoOnChange={false}
        label="Date"
        onChange={(val: string) => {
          setDate(val);
        }}
        value={form.values.date}
      />
    </div>
  );
};

export default function TransferDetails() {
  const { useIsInstantEligible } = modules.transfers;
  const { useSetInitialSteps } = useSudoContext();
  const { l10n } = useLocalization();
  const navigate = useNavigate();
  const form = modules.transfers.TransferForm.useForm();
  const eligible = useIsInstantEligible();

  useSetInitialSteps(TOTAL_STEPS);

  const shouldShowScheduling = !eligible || !form.values.instant;

  const handleSubmit = () => {
    navigate(`/${TRANSFERS_ROUTE}/${TRANSFER_REVIEW_ROUTE}`);
  };
  return (
    <ResponsiveFlex gapSize="m">
      <HeaderWithSteps
        headerText="Make a transfer"
        step={2}
        totalSteps={TOTAL_STEPS}
      />
      {eligible && <DeliverySpeedSelection />}

      <h4 className="fontSize--m nds-sans padding--top--xs padding--bottom--s">
        Additional details
      </h4>
      {shouldShowScheduling && <ScheduledTransferFields />}
      <TextInput label="Memo" {...formAdapters.input(form, "memo")} />

      <ProgressButtons
        backLabel={l10n.getString("button-back")}
        nextLabel={l10n.getString("button-next")}
        onBack={() => {
          navigate(`transfers/${TRANSFERS_ROUTE}/`);
        }}
        onNext={handleSubmit}
      />
    </ResponsiveFlex>
  );
}
