import { useCallback } from "react";

export const useHandleAccountsPageSubmit = ({
  onSubmitSuccessful,
}: {
  onSubmitSuccessful: () => void;
}) => {
  const handleRecipientPageSubmit = useCallback(async () => {
    onSubmitSuccessful();
  }, [onSubmitSuccessful]);

  return handleRecipientPageSubmit;
};
