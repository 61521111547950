import { ReactElement, useContext } from "react";
import { useLocalization } from "@fluent/react";
import {
  Drawer,
  ContentCard,
  Row,
  Tag,
  TruncatedAccount,
  Tooltip,
  Button,
} from "cerulean";
import Filters from "byzantine/src/filters";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import type Account from "byzantine/src/Account";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import AccountContext from "../contexts/AccountContext";
import DrawerLayout from "../DrawerLayout";
import styles from "./DualApproval.module.scss";
import { useCurrentUser } from "../contexts/CurrentUserContext";

export type DualApprovalDrawerProps = {
  handleClose: () => void;
  isOpen: boolean;
  showControls: boolean;
  drawerType: "pending" | "history";
  dualApproval: DualApprovalRequest | null;
  handleApprove?: (callback?: (error?: Error) => void) => void;
  handleReject?: () => void;
  handleOnNext: () => void;
  handleOnPrev: () => void;
};

const DualApprovalDrawer = ({
  handleClose,
  isOpen,
  showControls,
  drawerType,
  dualApproval,
  handleApprove,
  handleReject,
  handleOnNext,
  handleOnPrev,
}: DualApprovalDrawerProps) => {
  if (!dualApproval || !isOpen) return null;

  const { l10n } = useLocalization();

  const { action, created_at: paymentCreatedAt, requester } = dualApproval;
  const { type, dollarAmount, state: status, recipientName } = action;
  const { first_name: firstName, last_name: lastName } = requester;
  const { currentUser } = useCurrentUser();

  const isPendingDrawer = drawerType === "pending";
  const isHistoryDrawer = drawerType === "history";
  const isOwnRequest = currentUser?.uuid === requester.uuid;
  const showActions =
    isPendingDrawer && handleApprove && handleReject && !isOwnRequest;
  const isWirePayment = type === "Wire";
  const isACHPayment = type === "ACH";

  const renderRow = (
    row: [string, string | undefined | ReactElement],
    index: number,
  ) => {
    const [heading, content] = row;

    return content ? (
      <div key={`row-${heading}__${index}`} className="margin--bottom--l">
        <Row>
          <Row.Item>
            <p className="fontColor--secondary">{heading}</p>
          </Row.Item>
          <span className={styles.rowContent}>
            <Row.Item shrink>
              {typeof content === "string" ? <p>{content}</p> : content}
            </Row.Item>
          </span>
        </Row>
      </div>
    ) : null;
  };

  const TopCard = () => {
    const typeCopy = isWirePayment ? "Wire" : "Standard ACH";

    return (
      <div className="margin--top--l">
        <ContentCard kind="bordered">
          <h5 className="fontSize--m fontWeight--normal margin--bottom--xs">{`${typeCopy} to ${recipientName}`}</h5>
          <h1 className="fontColor--primary">
            {Filters.currency(dollarAmount, { hasDecimal: true })}
          </h1>
          <hr className="margin--bottom--l margin--top--l" />
          <h5 className="fontSize--s fontColor--secondary margin--bottom--xxs fontWeight--normal">
            {l10n.getString("label-scheduled-date")}
          </h5>
          <span>
            {paymentCreatedAt.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </span>
        </ContentCard>
      </div>
    );
  };

  const RecipientDetailsCard = () => {
    let showRecentlyChangedTag;
    let rows: Array<[string, string | undefined]> = [];

    if (isWirePayment) {
      const {
        to_account_institution_name: recipientAccountInstitution,
        to_account_number: recipientAccountNumber,
        to_account_routing_number: recipientRoutingNumber,
        recipientFormattedAddress,
      } = action;

      rows = [
        [l10n.getString("ach-payment-recipient-title"), recipientName],
        [
          l10n.getString("ach-payment-review-bank"),
          recipientAccountInstitution,
        ],
        [l10n.getString("ach-payment-review-account"), recipientAccountNumber],
        [l10n.getString("ach-payment-review-routing"), recipientRoutingNumber],
        [l10n.getString("label-address"), recipientFormattedAddress],
      ];
    } else if (isACHPayment) {
      const {
        recipientAccountNumber,
        recipientRoutingNumber,
        recipientAccountInstitution,
        recipientLastUpdated,
      } = action;

      const recipientUpdatedDate = new Date(recipientLastUpdated);
      const threeDaysAgoFromPaymentSubmitted = new Date(paymentCreatedAt);
      threeDaysAgoFromPaymentSubmitted.setDate(
        threeDaysAgoFromPaymentSubmitted.getDate() - 3,
      );
      showRecentlyChangedTag =
        recipientUpdatedDate > threeDaysAgoFromPaymentSubmitted;

      rows = [
        [l10n.getString("ach-payment-recipient-title"), recipientName],
        [
          l10n.getString("ach-payment-review-bank"),
          recipientAccountInstitution,
        ],
        [l10n.getString("ach-payment-review-account"), recipientAccountNumber],
        [l10n.getString("ach-payment-review-routing"), recipientRoutingNumber],
      ];
    }

    return (
      <div className="margin--top--xl">
        <Row alignItems>
          <Row.Item>
            <h5 className="fontColor--secondary fontWeight--bold margin--bottom--xs lineHeight--body-text">
              {l10n.getString("recipient-details").toUpperCase()}
            </h5>
          </Row.Item>
          {showRecentlyChangedTag ? (
            <Row.Item shrink>
              <div className="margin--bottom--xs">
                <Tooltip
                  maxWidth="210px"
                  text={l10n.getString("pending-drawer-recently-changed-copy")}
                >
                  <Button kind="plain">
                    <Tag kind="error" label="Recently changed" />
                  </Button>
                </Tooltip>
              </div>
            </Row.Item>
          ) : null}
        </Row>
        <ContentCard kind="bordered">
          {rows.map((row, index) => renderRow(row, index))}
        </ContentCard>
      </div>
    );
  };

  const PaymentDetailsCard = () => {
    const { wire_display_fee: wireFee } = useContext(
      InstitutionSettingsContext,
    );
    const { accounts } = useContext(AccountContext);
    const rows: Array<[string, string | undefined | ReactElement]> = [];

    if (isWirePayment) {
      const {
        template_name: templateName,
        wire_reason: wireReason,
        formattedMemo,
      } = action;

      rows.push(
        [l10n.getString("ach-payment-review-from"), recipientName],
        [l10n.getString("label-wire-template--template-name"), templateName],
        [
          l10n.getString("label-wire-template--wire-reason-required"),
          wireReason,
        ],
        [l10n.getString("label-wire-fee"), wireFee],
        [l10n.getString("label-memo"), formattedMemo],
      );
    } else if (isACHPayment) {
      const {
        company_id: companyId,
        company_name: companyName,
        entry_desc: entryDescription,
        institution_account: institutionAccount,
        std_ent_cls_code: transactionType,
      } = action;
      const fromAccount = accounts.find(
        (account: Account) => account.id === institutionAccount,
      );
      const truncatedAccountEl = (
        <TruncatedAccount
          name={fromAccount.name}
          lastFour={fromAccount.getMaskedNumber()}
        />
      );

      rows.push(
        [l10n.getString("ach-payment-review-from"), truncatedAccountEl],
        [
          l10n.getString("ach-payment-transaction-title"),
          `${transactionType} payment`,
        ],
        [l10n.getString("label-company-name"), companyName],
        [l10n.getString("label-company-id"), companyId],
        [l10n.getString("label-entry-description"), entryDescription],
      );
    }

    // common rows
    rows.push(
      [l10n.getString("label-submitted-by"), `${firstName} ${lastName}`],
      [
        l10n.getString("label-submitted-on"),
        paymentCreatedAt.toLocaleDateString("en-US"),
      ],
    );

    return (
      <div className="margin--top--xl">
        <Row>
          <Row.Item>
            <h5 className="margin--bottom--xs fontColor--secondary fontWeight--bold">
              {l10n
                .getString("ach-payment-payment-details-title")
                .toUpperCase()}
            </h5>
          </Row.Item>
        </Row>
        <ContentCard kind="bordered">
          {rows.map((row, index) => renderRow(row, index))}
        </ContentCard>
      </div>
    );
  };

  const StatusTag = () => {
    const today = new Date();
    // TODO: check if wire and ach match these status
    const tagKind =
      status === "rejected_approval" || status === "canceled"
        ? "error"
        : "success";

    // always show status tag on history drawer
    if (isHistoryDrawer) {
      return <Tag kind={tagKind} label={Filters.humanize(status)} />;
    } else if (
      isPendingDrawer &&
      status === "awaiting_approval" &&
      today.setHours(0, 0, 0, 0) >
        new Date(paymentCreatedAt).setHours(0, 0, 0, 0)
    ) {
      // only show "overdue" tag on pending drawer
      return (
        <Tooltip
          maxWidth="210px"
          text={l10n.getString("pending-drawer-overdue-copy")}
        >
          <Button kind="plain">
            <Tag kind="error" label="Overdue" />
          </Button>
        </Tooltip>
      );
    }

    return null;
  };

  const DeliveryCopy = () => {
    if (isPendingDrawer) {
      return (
        <div className="fontSize--xs margin--top--xs">
          {isWirePayment
            ? l10n.getString("wire-delivery-copy")
            : l10n.getString("ach-delivery-copy")}
        </div>
      );
    }
    return null;
  };

  const renderDrawerContent = () => (
    <>
      <Row alignItems>
        <Row.Item shrink>
          <h1 className="padding--top--l">
            {isPendingDrawer
              ? l10n.getString("pending-drawer-title")
              : l10n.getString("history-drawer-title")}
          </h1>
        </Row.Item>
        <Row.Item>
          <div className="margin--top--l">
            <StatusTag />
          </div>
        </Row.Item>
      </Row>
      <TopCard />
      <DeliveryCopy />
      <RecipientDetailsCard />
      <PaymentDetailsCard />
    </>
  );

  return (
    <Drawer
      depth="524px"
      isOpen={isOpen}
      showControls={showControls}
      onUserDismiss={handleClose}
      paddingSize={showActions ? "none" : "xl"}
      onNext={handleOnNext}
      onPrev={handleOnPrev}
    >
      {showActions ? (
        <DrawerLayout
          onSave={handleApprove}
          onCancel={handleReject}
          cancelLabel={"Reject"}
          saveLabel={"Approve"}
        >
          {renderDrawerContent()}
        </DrawerLayout>
      ) : (
        <>{renderDrawerContent()}</>
      )}
    </Drawer>
  );
};

export default DualApprovalDrawer;
