/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
declare global {
  namespace API {
    type AddressType = "primary" | "mailing";

    type Address = {
      street_address: string;
      street_address_2: string;
      city: string;
      region_code: string;
      postal_code: string;
      country_code: string;
      type?: AddressType;
    };

    type NoAddress = {
      street_address: "";
      street_address_2: "";
      city: "";
      region_code: "";
      postal_code: "";
      country_code: "";
      type: "";
    };

    type MaybeAddress = Address | NoAddress;
  }
}

type DeserializedAddress = API.Address;

interface Address extends DeserializedAddress {}

class Address {
  static readonly PRIMARY = "primary";

  static readonly MAILING = "mailing";

  static readonly COUNTRIES = {
    US: "US",
  };

  constructor(props: Partial<API.MaybeAddress> = {}) {
    Object.assign(this, {
      street_address: "",
      street_address_2: "",
      city: "",
      region_code: "",
      postal_code: "",
      country_code: Address.COUNTRIES.US,
      ...props,
    });
  }

  toString() {
    let fullStreetAddress = "";
    if (this.street_address) {
      fullStreetAddress = this.street_address_2
        ? `${this.street_address} ${this.street_address_2},`
        : `${this.street_address},`;
    }
    // eslint-disable-next-line no-return-assign
    return [
      fullStreetAddress,
      this.city ? `${this.city},` : "",
      this.region_code,
      this.postal_code,
      // ToDo: figure out what this is trying to do and do it some other way
      // when country_code != street_address, lets 'Same as
      // physical/residential address' display for Mailing Address, rather than US.
      (this.country_code = this.street_address ? this.country_code : ""),
    ]
      .filter((a) => a)
      .join(" ");
  }

  toJSON() {
    return {
      street_address: this.street_address,
      street_address_2: this.street_address_2,
      city: this.city,
      region_code: this.region_code,
      postal_code: this.postal_code,
      country_code: this.country_code,
    };
  }

  validate(): asserts this is API.Address {
    const requiredAddressFields = [
      "street_address",
      "city",
      "region_code",
      "postal_code",
      "country_code",
    ] as const;
    requiredAddressFields.forEach((field) => {
      if (!this[field]) throw new Error(`Address field ${field} is required`);
    });
  }

  isPrimary() {
    return this.type === Address.PRIMARY;
  }

  isMailing() {
    return this.type === Address.MAILING;
  }
}

export function updateAddress(
  previous?: Address | null,
  update?: Address,
): Address;
export function updateAddress(
  previous: any,
  update: Address | null,
): Address | null;
export function updateAddress(
  previous: Address | null = new Address(),
  update: Address | null = new Address(),
) {
  if (update === null) return null;
  return new Address({
    ...(previous || new Address()),
    ...update,
  });
}

export default Address;
