// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deliverySpeedSelectContainer--BB1yL{display:inline-flex;width:100%}.deliverySpeedSelectCard--T2nUH{height:100%}.deliverySpeedSelectCard--T2nUH:first-child{margin-right:var(--space-xs);width:16em}", "",{"version":3,"sources":["webpack://./components/transfer/funds/TransferDetails.module.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CACA,UAAA,CAGF,gCACE,WAAA,CAGF,4CACE,4BAAA,CACA,UAAA","sourcesContent":[".deliverySpeedSelectContainer {\n  display: inline-flex;\n  width: 100%;\n}\n\n.deliverySpeedSelectCard {\n  height: 100%;\n}\n\n.deliverySpeedSelectCard:first-child {\n  margin-right: var(--space-xs);\n  width: 16em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deliverySpeedSelectContainer": "deliverySpeedSelectContainer--BB1yL",
	"deliverySpeedSelectCard": "deliverySpeedSelectCard--T2nUH"
};
export default ___CSS_LOADER_EXPORT___;
