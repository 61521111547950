import * as Yup from "yup";
import { DateTime } from "luxon";

import {
  generateRecurrenceRuleString,
  FREQUENCIES,
} from "../../../../Recurrence";
import { createForm } from "../../forms";
import { cents } from "../../../utils";

export type TransferFundsForm = {
  fromAccount: API.AccountId;
  toAccount: API.AccountId;
  amount: Cents;
  agreement: boolean;
  instant: boolean | undefined;
  memo: string | undefined;
  transferUuid: string | undefined;
  date: string | undefined;
  frequency: string | undefined;
};

export const validationSchema = Yup.object().shape({
  fromAccount: Yup.string().required("Required"),
  toAccount: Yup.string().required("Required"),
  amount: Yup.number()
    .integer("Please enter a number.")
    .positive("Please enter a positive amount.")
    .required("Required"),
  agreement: Yup.boolean()
    .equals([true], "Must agree to transfer terms.")
    .required("Required."),
  instant: Yup.boolean().optional(),
  memo: Yup.string().max(128, "Cannot be more than 128 characters.").optional(),
  transferUuid: Yup.string().optional(),
  date: Yup.string().optional(),
  frequency: Yup.string().optional(),
});

export const initialValues: TransferFundsForm = {
  fromAccount: "" as API.AccountId,
  toAccount: "" as API.AccountId,
  amount: cents(0),
  memo: "",
  agreement: false,
  instant: false,
  transferUuid: undefined,
  date: undefined,
  frequency: undefined,
};

export const transformImmediateTransferFundsFormFieldsToApiFields = ({
  fromAccount: from_account_id,
  toAccount: to_account_id,
  amount,
  agreement,
  instant,
  transferUuid: transfer_uuid,
  date,
  frequency,
}: TransferFundsForm) => {
  const fields = {
    amount,
    from_account_id,
    to_account_id,
    agreement,
    instant,
    transfer_uuid,
  } as API.TransferScheduledRequest;
  if (!instant && date && frequency && frequency !== FREQUENCIES.ONCE) {
    // only add the recurring rule optional body param for scheduled transfers
    // when all the scheduled fields have been filled in.
    const convertedDate = DateTime.fromFormat(date, "MM/dd/yyyy").toFormat(
      "yyyy-MM-dd",
    );
    fields.recurring_rule = generateRecurrenceRuleString(
      frequency,
      convertedDate,
    );
  }

  return fields;
};

export const TransferForm = createForm({
  initialValues,
  validationSchema,
});
