import { useCallback } from "react";

import { useDispatch } from "react-redux";

import {
  combineRunProps,
  type OverrideRunProps,
  useRequestWithFeedback,
  type AnyException,
} from "../../../composites";
import { useObjectMemo } from "../../../hooks";
import { useFeature } from "../features";
import { actions } from "../../entities/transfers";

import { useSelectedDestinationAccount } from "./hooks";
import { API } from "./api";

export const useCheckDestinationInstantEligibility = () => {
  const { send, loading } = useRequestWithFeedback<
    API.InstantEligibilityResponse,
    AnyException
  >();
  const dispatch = useDispatch();
  const { featureEnabled } = useFeature();
  const destination = useSelectedDestinationAccount();
  const checkDestinationInstantEligibility = useCallback(
    (
      overrideRunProps: OverrideRunProps<
        API.InstantEligibilityResponse,
        AnyException
      > = {},
    ) => {
      if (
        !destination ||
        destination.source === "institution" ||
        !featureEnabled("fednow_send")
      ) {
        dispatch(actions.resetEligible());
        return;
      }
      send({
        action: API.checkInstantEligible({
          routing_number: destination.routing,
        }),
        ...combineRunProps<API.InstantEligibilityResponse, AnyException>(
          {
            onError: (_) => {
              dispatch(actions.resetEligible());
            },
            onSuccess(data) {
              dispatch(actions.eligible(data));
            },
          },
          overrideRunProps,
        ),
      });
    },
    [destination, send, dispatch, featureEnabled],
  );

  return useObjectMemo({ checkDestinationInstantEligibility, loading });
};
